import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isArray, concat } from 'lodash';
import { withTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import {
  FaCog,
  FaUser,
  FaRegUser,
  FaCreditCard,
  FaReceipt,
  FaDatabase,
  FaBook,
  FaHome,
  FaUsers,
  FaRegListAlt,
} from 'react-icons/fa';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { MdLockOpen } from 'react-icons/md';
import { getAccountId, getId, setLogoBase64, setRounding, getRelogin } from '../../localStorage';
import {
  getRoleAndRoleGroupById,
  getRoleById,
  getRoleIdByRoleGroups,
  getTenantDefaultConfig,
  getAwsS3Credential,
  readFileFromS3,
  getLogoSuccess,
  getCurrencyConfigApp,
  getAccountById,
  getCcpPropertiesConfig,
} from '../../containers/App/actions';
import RouterNames from '../../containers/App/RouteNames';
import { checkPermissionForAllPages } from '../../containers/App/CheckPermission';
import {
  makeGetPermissionSelfCare,
  makeGetLogoConfig,
  makeGetAccountInfo,
  makeGetCcpProperty,
  makeGetPermissionsReports,
  makeGetCurrencyConfig,
  makeGetUserDetails,
} from '../../containers/App/selectors';
import user from '../../assets/images/user-default.jpg';
import { itemLangSelected, listLangNotSelect } from '../../utils/listLangue';
import { getBase64 } from '../../utils/utils';
import { checkPermissionThirdPartySelfCare, checkPermissionViewAccountSearch } from '../../utils/CheckPermissions';
import './styles.scss';

class MainHeader extends React.PureComponent {
  state = {
    lang: localStorage.getItem('i18nextLng') || 'en-US',
  };

  componentDidMount() {
    const {
      getRoleAndRoleGroupById,
      getRoleById,
      getRoleIdByRoleGroups,
      permissionsSelfCare,
      getTenantDefaultConfig,
      getAwsS3Credential,
      readFileFromS3,
      getLogoSuccess,
      logoBase64,
      getCurrencyConfigApp,
      getAccountById,
      accountInfo,
      getCcpPropertiesConfig,
      ccpProperty,
      currencyConfig,
    } = this.props;
    if (!permissionsSelfCare || !permissionsSelfCare.selfCareModulePermissions)
      getRoleAndRoleGroupById(getId(), ({ success, data }) => {
        if (success && data) {
          if (data.roles && isArray(data.roles)) {
            const groupIdRoles = data.roles.map(item => item.roleId);
            groupIdRoles.map(id => getRoleById(id));
          }
          // Handle with role groups
          if (data.roleGroups && isArray(data.roleGroups) && !data.roles) {
            const roleGroupId = data.roleGroups.map(item => item.roleGroupId);
            let listRoleId = [];
            let countDownFetchRoleGroup = roleGroupId.length;
            roleGroupId.map(id =>
              getRoleIdByRoleGroups(id, ({ success, data }) => {
                countDownFetchRoleGroup -= 1;
                if (success && data.length) {
                  listRoleId = concat(
                    listRoleId,
                    data.flatMap(item => item.roleId)
                  );
                  if (countDownFetchRoleGroup === 0) {
                    listRoleId.map(roleId => getRoleById(roleId));
                  }
                }
              })
            );
          }
        }
      });

    if (!logoBase64) {
      getTenantDefaultConfig(({ success, data }) => {
        if (success && data && data.tenantProfile && data.tenantProfile.logoPath) {
          const pathFile = data.tenantProfile.logoPath;
          getAwsS3Credential('', ({ success, data }) => {
            if (success && data) {
              readFileFromS3({ ...data, url: pathFile }, ({ success, data }) => {
                if (success) {
                  getBase64(data, result => {
                    setLogoBase64(result);
                    getLogoSuccess(result);
                  });
                }
              });
            }
          });
        }
      });
    }

    if (!currencyConfig || !currencyConfig.id || !currencyConfig.currencyListConfig) {
      getCurrencyConfigApp('', ({ success, data }) => {
        if (success && data && data.currencyListConfig && data?.currencyListConfig[0]?.roundingPrecision) {
          setRounding(data.currencyListConfig[0].roundingPrecision);
        }
      });
    } else {
      setRounding(currencyConfig.currencyListConfig[0].roundingPrecision);
    }

    if (!accountInfo || !accountInfo.id || accountInfo.id !== getAccountId()) {
      getAccountById({ id: getAccountId() });
    }
    if (!ccpProperty || !ccpProperty.ccpPropertyList) getCcpPropertiesConfig('');
  }

  onChangeLang = lang => {
    const { i18n } = this.props;
    this.setState({ lang });
    i18n.changeLanguage(lang);
  };

  toggleFullscreen = event => {
    let element = document.body;
    if (event instanceof HTMLElement) {
      element = event;
    }
    const isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;
    element.requestFullScreen =
      element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen;
    document.cancelFullScreen =
      document.cancelFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen;
    return isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
  };

  onOpenMcmSelfCare = url => {
    const encodedData = window.btoa(url); // encode a string
    const mcmDomain = process.env.REACT_APP_MCM_SELF_CARE_PORTAL_DOMAIN;
    window.open(`${mcmDomain}/DescargaCDRs/login.aspx?VE=${encodedData}`);
  };

  render() {
    const {
      activeTab,
      isHiddenProfile = false,
      t,
      logoBase64,
      permissionsSelfCare,
      accountInfo,
      permissionsReports,
      userDetails,
      history,
    } = this.props;
    const { lang } = this.state;

    let modeViewThirdPartySelfCare = 0;
    let modeViewAccounts = 0;
    if (permissionsSelfCare && permissionsSelfCare.selfCareModulePermissions) {
      const listPermission = permissionsSelfCare.selfCareModulePermissions;
      modeViewThirdPartySelfCare = checkPermissionThirdPartySelfCare({
        listPermission,
      });
      modeViewAccounts = checkPermissionViewAccountSearch({
        listPermission,
      });
    }

    let permissions = {};
    if (permissionsReports && permissionsReports.reportModulePermissions) {
      const listPermission = permissionsReports.reportModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }

    const {
      modeGetRawCdrReport,
      modeGetBroadsoftZeroDurationCdrs,
      modeGetNonBroadsoftMapReport,
      modeGetNonBroadsoftZeroDurationCdrs,
      modeGetRawSmsReport,
      modeGetDataServiceReport,
    } = permissions;

    return (
      <div className="main-header">
        <header>
          <nav className="navbar navbar-expand-lg fixed-top header-container top-header">
            <Link className="header-home-page" to="/">
              {logoBase64 && (
                <img
                  src={logoBase64}
                  alt="logo"
                  height="auto"
                  style={{ padding: '0.5rem', width: 'auto', maxHeight: '50px' }}
                />
              )}
            </Link>
            <button type="button" className="btncall">
              {`${t('label.contactAgent')} : 18001112222`}
            </button>
            <div className="col-md-6 profile-group">
              <ul className="sidebarnav navbar-nav side-nav-profile ml-auto">
                <li
                  className="ml-auto col-md-2 menu-profile-top"
                  style={{
                    backgroundColor: 'transparent',
                    marginRight: 20,
                    zIndex: 100,
                  }}
                >
                  <a
                    className="dropdown-toggle has-arrow waves-effect waves-dark"
                    href="#"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <img src={itemLangSelected(lang || 'en-US').flag} alt="flag" className="flag-icon" />
                    <span className="title-language">{itemLangSelected(lang || 'en-US').name}</span>
                  </a>
                  <ul aria-expanded="false" className="collapse menu-profile">
                    <li>
                      <div>
                        {listLangNotSelect(lang || 'en-US').map(item => (
                          <button
                            key={item.key}
                            type="button"
                            className="dropdown-item drd-proflie btn-flag"
                            onClick={() => this.onChangeLang(item.key)}
                          >
                            <span className="">
                              <img src={item.flag} alt="flag" className="flag-icon-item mr-2" />
                            </span>
                            <span className="title-language">{item.name}</span>
                          </button>
                        ))}
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div>
              <button type="button" id="btnFullscreen" className="btn-full-screen" onClick={this.toggleFullscreen}>
                <BsArrowsFullscreen />
              </button>
            </div>
            {!isHiddenProfile && (
              <ul className="sidebarnav ml-auto navbar-nav side-nav-profile">
                <li
                  className="col-md-1"
                  style={{
                    backgroundColor: 'transparent',
                    marginRight: 20,
                    zIndex: 100,
                  }}
                >
                  <a
                    className="dropdown-toggle has-arrow waves-effect waves-dark"
                    href="#"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <img src={user} className="profileimg" />
                    <span className="">
                      &nbsp;
                      {getAccountId() || ''}
                      &nbsp;
                    </span>
                  </a>
                  <ul aria-expanded="false" className="collapse menu-profile">
                    <li>
                      <div>
                        <Link to="/" className="dropdown-item drd-proflie">
                          <FaRegUser color="#FED505" />
                          &nbsp;
                          {t('label.myProfile')}
                        </Link>
                        <div className="dropdown-divider" />
                        <Link to="/" className="dropdown-item drd-proflie">
                          <FaCog color="#249730" />
                          &nbsp;
                          {t('label.accountSetting')}
                        </Link>
                        <div className="dropdown-divider" />
                        <Link
                          to="/login"
                          className="dropdown-item drd-proflie"
                          onClick={() => {
                            localStorage.clear();
                          }}
                        >
                          <MdLockOpen color="#CD0101" />
                          &nbsp;
                          {t('label.logout')}
                        </Link>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            )}
          </nav>
        </header>
        <nav className="sidebar-nav">
          <ul className="sidebarnav fixed-top nav-menu">
            <li className={`${activeTab === 'Dashboard' ? 'active' : ''}`}>
              <Link to="/">
                <i className="iconcolor">
                  <FaHome />
                </i>
                {t('label.home')}
              </Link>
            </li>
            <li className={`${activeTab === 'user-profile' ? 'active' : ''}`}>
              <Link to="/manage-profile">
                <i className="iconcolor">
                  <FaUser />
                </i>
                {t('label.userProfile')}
              </Link>
            </li>

            <li className={`menu-item ${activeTab === 'AccountData' ? 'active' : ''}`}>
              <a className="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false">
                <i className="">
                  <FaDatabase />
                </i>
                <span className="hide-menu">{t('label.accountData')}</span>
              </a>
              <ul aria-expanded="false" className="collapse">
                <li>
                  {/* <Link to="/purchase-order">{t('label.purchaseOrder')}</Link> */}
                  <Link to="/account-info">{t('label.accountInfo')}</Link>
                </li>
                <li>
                  {/* <Link to="/purchase-order">{t('label.purchaseOrder')}</Link> */}
                  <Link to="/custom-attributes">{t('label.customAttributes')}</Link>
                </li>
                <li>
                  {/* <Link to="/billing-profile">{t('label.billingProfile')}</Link> */}
                  <Link to="/billing-profile">{t('label.billingProfile')}</Link>
                </li>
                <li>
                  {/* <Link to="/purchase-order">{t('label.purchaseOrder')}</Link> */}
                  <Link to="/purchase-order">{t('label.purchaseOrder')}</Link>
                </li>
              </ul>
            </li>

            <li className={`menu-item ${activeTab === 'ManageServices' ? 'active' : ''}`}>
              <a className="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false">
                <i className="">
                  <FaBook />
                </i>
                <span className="hide-menu">{t('label.subscriptionData')}</span>
              </a>
              <ul aria-expanded="false" className="collapse">
                <li>
                  <Link to="/manage-service">{t('label.subscription')}</Link>
                </li>
                <li>
                  {/* <Link to="/purchase-order">{t('label.orders')}</Link> */}
                  <Link to="/orders">{t('label.orders')}</Link>
                </li>
              </ul>
            </li>

            {/* <li className={`${activeTab === 'ManageServices' ? 'active' : ''}`}>
              <Link to="/manage-service" aria-expanded="false">
                <i className="iconcolor">
                  <FaEye />
                </i>
                <span className="hide-menu">{t('label.subscription')}</span>
              </Link>
            </li> */}

            <li className={`menu-item ${activeTab === 'MakePayment' ? 'active' : ''}`}>
              <a className="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false">
                <i className="">
                  <FaCreditCard />
                </i>
                <span className="hide-menu">{t('label.activity')}</span>
              </a>
              <ul aria-expanded="false" className="collapse activity-menu">
                <li>
                  <Link to="/manage-credit-card">{t('label.managePaymentProfile')}</Link>
                </li>
                <li>
                  <Link to="/make-payment">{t('label.makePayment')}</Link>
                </li>
                {!!modeViewThirdPartySelfCare && (
                  <li>
                    <a
                      type="button"
                      onClick={evt =>
                        this.onOpenMcmSelfCare(
                          `EmbrixToken=${process.env.REACT_APP_MCM_SELF_CARE_PORTAL_EMBRIX_TOKEN}&MCMclientID=${accountInfo?.clientId}&EmbrixRequest=Portabilidad`
                        )
                      }
                    >
                      {t('label.numberPortability')}
                    </a>
                  </li>
                )}
              </ul>
            </li>

            <li className={`menu-item ${activeTab === 'Billing Data' ? 'active' : ''}`}>
              <a className="has-arrow waves-effect waves-dark " href="javascript:void(0)" aria-expanded="false">
                <i className="iconcolor">
                  <FaReceipt />
                </i>
                <span className="hide-menu">
                  {t('label.billingData')}
                  <span className="badge badge-pill badge-cyan ml-auto" />
                </span>
              </a>
              <ul aria-expanded="false" className="collapse dropdown-menu">
                <li>
                  <Link to="/view-invoices">{t('label.viewBills')}</Link>
                </li>
                <li>
                  <Link to="/view-credit-notes">{t('label.viewCreditNotes')}</Link>
                </li>
                <li>
                  <Link to="/view-payment-details">{t('label.viewPayment')}</Link>
                </li>
                <li>
                  <Link to="/manage-balances">{t('label.viewBalances')}</Link>
                </li>
                <li>
                  <Link to="/view-transactions">{t('label.viewTransactions')}</Link>
                </li>
                <li>
                  <Link to="/ar-activity">{t('label.viewARActivity')}</Link>
                </li>
              </ul>
            </li>
            {/* <li className={`${activeTab === 'Ar Activity' ? 'active' : ''}`}>
              <Link to="/ar-activity">
                <i className="iconcolor">
                  <FaTasks />
                </i>
                {t('label.aRActivity')}
              </Link>
            </li> */}
            <li className={`menu-item ${activeTab === 'Reports' ? 'active' : ''}`}>
              <a className="has-arrow waves-effect waves-dark " href="javascript:void(0)" aria-expanded="false">
                <i className="iconcolor">
                  <FaRegListAlt />
                </i>
                <span className="hide-menu">
                  {t('label.reports')}
                  <span className="badge badge-pill badge-cyan ml-auto" />
                </span>
              </a>
              <ul aria-expanded="false" className="collapse dropdown-menu navbar-nav">
                <li>
                  <Link to="/account-statement">{t('label.accountStatement')}</Link>
                </li>
                <li>
                  <Link to="/view-account-activity">{t('label.accountActivity')}</Link>
                </li>
                <li>
                  <Link to="/view-collections">{t('label.collectionActivity')}</Link>
                </li>
                {!!modeViewThirdPartySelfCare && (
                  <li>
                    <a
                      type="button"
                      onClick={evt =>
                        this.onOpenMcmSelfCare(
                          `EmbrixToken=${process.env.REACT_APP_MCM_SELF_CARE_PORTAL_EMBRIX_TOKEN}&MCMclientID=${accountInfo?.clientId}&EmbrixRequest=CDRS`
                        )
                      }
                    >
                      {t('label.historicalCDR’s')}
                    </a>
                  </li>
                )}
                {(!!modeGetRawCdrReport ||
                  !!modeGetBroadsoftZeroDurationCdrs ||
                  !!modeGetNonBroadsoftMapReport ||
                  !!modeGetNonBroadsoftZeroDurationCdrs ||
                  !!modeGetRawSmsReport ||
                  !!modeGetDataServiceReport) && (
                  <li className="dropdown-submenu">
                    <span className="dropdown-item dropdown-toggle dropdown-toggle-item-menu" href="http://google.com">
                      {t('label.CDRReports')}
                    </span>
                    <ul className="dropdown-menu">
                      {!!modeGetRawCdrReport && (
                        <li>
                          <Link to={RouterNames.rawCdr.path}>{t('label.rawBroadsoft')}</Link>
                        </li>
                      )}
                      {!!modeGetBroadsoftZeroDurationCdrs && (
                        <li>
                          <Link to="/broadsoft-zero-duration-cdrs">{t('label.rawBroadsoftZeroDurationCDR')}</Link>
                        </li>
                      )}
                      {!!modeGetNonBroadsoftMapReport && (
                        <li>
                          <Link to={RouterNames.nonBroadSoftRawCdrData.path}>{t('label.metaswitch')}</Link>
                        </li>
                      )}
                      {!!modeGetNonBroadsoftZeroDurationCdrs && (
                        <li>
                          <Link to={RouterNames.nonBroadsoftZeroDurationCdrs.path}>{t('label.metaswitchZero')}</Link>
                        </li>
                      )}
                      {!!modeGetRawSmsReport && (
                        <li>
                          <Link to={RouterNames.rawSMSFiles.path}>{t('label.rawSMSFiles')}</Link>
                        </li>
                      )}
                      {!!modeGetDataServiceReport && (
                        <li>
                          <Link to={RouterNames.onDemand.path}>{t('label.onDemand')}</Link>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
              </ul>
            </li>

            {!!modeViewAccounts && userDetails && userDetails.category === 'AGENT' && (
              <li className={`${activeTab === 'search-account' ? 'active' : ''}`}>
                <Link to={RouterNames.searchAccounts.path}>
                  <i className="iconcolor">
                    <FaUsers />
                  </i>
                  {t('label.accountSearch')}
                </Link>
              </li>
            )}

            <li className="title-account-number">
              <span className="has-arrow waves-effect waves-dark " href="javascript:void(0)" aria-expanded="false">
                <span className="">
                  {t('label.accountNumber')}
                  :
                  <span className="account-num-header">{getAccountId()}</span>
                  <span className="badge badge-pill badge-cyan ml-auto" />
                </span>
              </span>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

MainHeader.propTypes = {
  getRoleAndRoleGroupById: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  permissionsSelfCare: makeGetPermissionSelfCare() || {},
  logoBase64: makeGetLogoConfig() || {},
  accountInfo: makeGetAccountInfo() || {},
  ccpProperty: makeGetCcpProperty() || {},
  permissionsReports: makeGetPermissionsReports() || {},
  currencyConfig: makeGetCurrencyConfig() || {},
  userDetails: makeGetUserDetails() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getRoleAndRoleGroupById,
    getRoleById,
    getRoleIdByRoleGroups,
    getTenantDefaultConfig,
    getAwsS3Credential,
    readFileFromS3,
    getLogoSuccess,
    getCurrencyConfigApp,
    getAccountById,
    getCcpPropertiesConfig,
  })(MainHeader)
);
