import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the dashBoardPage state domain
 */

const selectDashBoardPageDomain = state =>
  state.get('dashBoardPage', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by DashBoardPage
 */

const makeSelectDashBoardPage = () =>
  createSelector(selectDashBoardPageDomain, substate => substate.toJS());

export default makeSelectDashBoardPage;
export { selectDashBoardPageDomain };
