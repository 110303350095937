import { call, takeLatest, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { push } from 'react-router-redux';
import { queryRequest, mutationRequest } from '../../utils/request';
import { NotificationTypes } from '../../constantsApp';
import {
  getSelfCareOffers,
  getBundleIdByPackageId,
  getAccountById,
  manageAccount,
  searchPackages,
  getRoleAndRoleGroupById,
  getPermissionsByRoleId,
  getRoleIdByRoleGroups,
  searchInvoiceUnits,
  modifyAccount,
  searchOrders,
  getCustomerActivity,
  getActivityById,
  searchNotesUnits,
  getExternalPOByAccountId,
  getCustomAttributesByObjectType,
  getS3Config,
  readFileFromS3,
  getTenantSelfCareConfig,
  getTenantConfig,
  getAwsS3Credential,
  getObjectFileById,
  searchPayment,
  redirectMCM,
  uploadfFile,
  getCurrencyConfig,
  getCcpProperties,
  getBroadsoftZeroDurationCdrsReport,
  getRawCdrData,
  getConfigFailureCodes,
  getConfigIdd,
  getConfigIdo,
  getConfigIncomingOutgoingRoute,
  getSplitTaxPerLine,
  getConfigFailureCodesList,
  getConfigSreserved,
  getConfigOrganization,
  getNonBroadSoftRawCdrData,
  getOutputTemplateByType,
  getNonBroadsoftZeroDurationCdrsReport,
  getRawSmsData,
  getDataServiceReportData,
  getDataServiceReportDetails,
  applyPayment,
  searchAccounts,
  getAccountDetail,
} from '../../api';
import { isConnecting, isEndConnected } from '../Loader/actions';
import { resetNotification, setNotification } from '../Notification/actions';
import * as types from './constants';
import * as actions from './actions';
import i18n from '../../i18next';

export function* getSelfCareOffersSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getSelfCareOffers(payload));
    if (cb) cb({ success: true, data: response.getSelfCareOffers });
  } catch (err) {
    if (cb) cb({ success: false });
  }
}

export function* getBundleByPackageIdSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getBundleIdByPackageId(payload));
    if (cb) cb({ success: true, data: response.getPackageById.components });
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
  }
}

// callback when create failed
export function* getAccountByIdSaga({ payload, cb }) {
  if (!payload || !payload.id || payload.id === 'null') return;
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const response = yield call(queryRequest, getAccountById(payload.id));
    if (response.getAccountById) {
      yield put(isEndConnected());
      if (cb) yield cb({ success: true, data: response.getAccountById });
      yield put(actions.getAccountByIdSuccess(response.getAccountById));
    } else {
      yield put(isEndConnected());
      yield put(
        setNotification({
          type: NotificationTypes.error,
          message: 'get account detail failure!',
        })
      );
      if (cb) yield cb({ success: false });
    }
  } catch (err) {
    yield put(
      setNotification({
        type: NotificationTypes.error,
        message: `get account detail failure! ${err}`,
      })
    );
    yield put(isEndConnected());
    yield cb({ success: false });
  }
}

export function* manageAccountSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const response = yield call(mutationRequest, manageAccount(payload));
    if (response.manageAccount) {
      yield put(isEndConnected());
      yield put(
        setNotification({
          type: NotificationTypes.success,
          message: 'modify Account successfully!',
        })
      );
      if (cb) cb({ success: true, data: response.manageAccount });
    } else {
      yield put(isEndConnected());
      yield put(
        setNotification({
          type: NotificationTypes.error,
          message: 'modify Account failure!',
        })
      );
      if (cb) yield cb({ success: false });
    }
  } catch (err) {
    yield put(isEndConnected());
    yield put(
      setNotification({
        type: NotificationTypes.error,
        message: `modify Account failure! ${err}`,
      })
    );
    if (cb) yield cb({ success: false });
  }
}

export function* searchPackageSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, searchPackages(payload));
    if (cb) cb({ success: true, data: response.searchPackages });
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
  }
}

export function* getRolesAndRoleGroupByUserIdSaga({ payload, cb }) {
  yield put(actions.getRoleAndRoleGroupByIdSuccess(null));
  try {
    const response = yield call(queryRequest, getRoleAndRoleGroupById(payload));
    if (response.getUserById) {
      yield put(actions.getRoleAndRoleGroupByIdSuccess(response.getUserById));
      if (cb) cb({ success: true, data: response.getUserById });
    }
  } catch (err) {
    if (cb) cb({ success: false, data: {} });
  }
}

export function* getRoleByIdSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getPermissionsByRoleId(payload));
    if (response.getRoleById) {
      yield put(actions.getPermissionByIdSuccess(response.getRoleById));
      if (cb) cb({ success: true, data: response.getRoleById });
    }
  } catch (err) {
    if (cb) cb({ success: false, data: {} });
  }
}

export function* getRoleIdByRoleGroupSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getRoleIdByRoleGroups(payload));
    if (response.getRoleGroupById) {
      if (cb)
        cb({
          success: true,
          data: response.getRoleGroupById.roleGroupRoles || null,
        });
    }
  } catch (err) {
    if (cb) cb({ success: false, data: null });
  }
}

export function* selectInvoiceUnitsId({ payload, cb }) {
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchInvoiceUnits({ page, size, filter, sort }));
    if (cb) cb({ success: true, data: response.searchInvoiceUnits });
  } catch (err) {
    if (cb) cb({ success: false });
  }
}

export function* modifyAccountByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const response = yield call(mutationRequest, modifyAccount(payload));
    if (cb) cb({ success: true, data: response.modifyAccount });
    if (response) {
      yield put(
        setNotification({
          type: NotificationTypes.success,
          message: 'modify Account successfully!',
        })
      );
    } else {
      yield put(
        setNotification({
          type: NotificationTypes.error,
          message: 'modify Account failure!',
        })
      );
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(
      setNotification({
        type: NotificationTypes.error,
        message: `modify Account failure! ${err}`,
      })
    );
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* searchOrdersSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchOrders({ page, size, filter, sort }));
    if (response.searchOrders) {
      if (cb) cb({ success: true, data: response.searchOrders });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* getCustomerActivitySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, getCustomerActivity({ page, size, filter, sort }));
    if (response && response.searchActivities) {
      if (cb) cb({ success: true, data: response.searchActivities });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getActivityByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getActivityById(payload));
    if (response.getActivityById) {
      if (cb) cb({ success: true, data: response.getActivityById });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* searchNotesUnitsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchNotesUnits(payload));
    if (response.searchNotesUnits) {
      if (cb) cb({ success: true, data: response.searchNotesUnits });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getExternalPOByAccountIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getExternalPOByAccountId(payload));
    if (response.getExternalPOByAccountId) {
      if (cb) cb({ success: true, data: response.getExternalPOByAccountId });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getCustomAttributesByObjectTypeSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCustomAttributesByObjectType());
    if (response.getCustomAttributesByObjectType) {
      if (cb) cb({ success: true, data: response.getCustomAttributesByObjectType });
    } else if (cb) cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* getS3BuctketSaga({ payload, cb }) {
  try {
    const response = yield call(getS3Config);
    yield put(actions.getS3BuctketSucess(response.data));
    if (cb) cb({ success: true, data: response });
  } catch (err) {
    if (cb) cb({ success: false });
  }
}

export function* readFileFromS3Saga({ payload, cb }) {
  // yield put(isConnecting());
  try {
    const response = yield call(readFileFromS3, payload);
    // yield put(isEndConnected());
    if (cb) cb({ success: true, data: response });
  } catch (err) {
    // yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* getTenantSelfCareSaga({ payload, cb }) {
  // yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTenantSelfCareConfig(payload));
    if (response.getTenantSelfCareConfig) {
      if (cb) cb({ success: true, data: response.getTenantSelfCareConfig });
      yield put(actions.getTenantSelfCareConfigSuccess(response.getTenantSelfCareConfig));
    } else {
      yield put(actions.getTenantSelfCareConfigSuccess(null));
      if (cb) cb({ success: true });
    }
    // yield put(isEndConnected());
  } catch (err) {
    yield put(actions.getTenantSelfCareConfigSuccess(null));
    if (cb) cb({ success: false });
    // yield put(isEndConnected());
  }
}

export function* getTenantDefaultConfigSaga({ cb }) {
  // yield put(isConnecting());
  try {
    // yield put(isConnecting());
    const response = yield call(queryRequest, getTenantConfig());
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
    } else if (cb) yield cb({ success: false });
    // yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    // yield put(isEndConnected());
  }
}

export function* getAwsS3CredentialSaga({ payload, cb }) {
  // yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAwsS3Credential(payload));
    // yield put(isEndConnected());
    if (response) {
      yield put(actions.getS3BuctketSucess(response.getAwsS3Credential));
      if (cb) cb({ success: true, data: response.getAwsS3Credential });
    } else if (cb) cb({ success: false, data: null });
  } catch (err) {
    // yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* getObjectFileByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getObjectFileById(payload));
    if (response.getObjectFileById) {
      cb(response.getObjectFileById);
    } else {
      cb(null);
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
  }
}

export function* searchPaymentByAccountSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchPayment(payload));
    if (response.searchPayment) {
      if (cb) cb({ success: true, data: response.searchPayment });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* redirectMCMSelfCareSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(redirectMCM());
    if (response.searchPayment) {
      if (cb) cb({ success: true, data: response.searchPayment });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* uploadFileSaga({ payload, cb, isSendEmail }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const response = yield call(uploadfFile, payload);
    if (response && response.data && response.data.errors && response.data.errors[0]) {
      yield put(
        setNotification({
          type: NotificationTypes.error,
          message: response.data.errors[0].message,
        })
      );
      if (cb) cb({ success: true });
    } else {
      if (isSendEmail) {
        yield put(
          setNotification({
            type: NotificationTypes.success,
            message: i18n.t('common:apiMessage.sentEmailSuccessfully'),
          })
        );
      } else {
        yield put(
          setNotification({
            type: NotificationTypes.success,
            message: i18n.t('common:apiMessage.createFileSuccessfully'),
          })
        );
      }

      if (cb) cb({ success: true, data: response });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* getCurrencyConfigAppSaga({ payload, cb }) {
  // yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCurrencyConfig(payload));
    // yield put(isEndConnected());
    if (response.getCurrencyConfig) {
      yield put(actions.getCurrencyConfigAppSuccess(response.getCurrencyConfig));
      if (cb) yield cb({ success: true, data: response.getCurrencyConfig });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    // yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getCcpPropertiesConfigSaga({ payload, cb }) {
  // yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCcpProperties(payload));
    if (response.getCcpProperties) {
      if (cb) cb({ success: true, data: response.getCcpProperties });
      yield put(actions.getCcpPropertiesConfigSuccess(response.getCcpProperties));
    } else {
      if (cb) cb({ success: true, data: null });
      yield put(actions.getCcpPropertiesConfigSuccess(null));
    }
    // yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(actions.getCcpPropertiesConfigSuccess(null));
    // yield put(isEndConnected());
  }
}

// getBroadsoftZeroDurationCdrsReport
export function* getBroadsoftZeroDurationCdrsReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBroadsoftZeroDurationCdrsReport(payload));
    if (response.getBroadsoftZeroDurationCdrsReport && response.getBroadsoftZeroDurationCdrsReport) {
      yield put(actions.getBroadsoftZeroDurationCdrsReportSuccess(response.getBroadsoftZeroDurationCdrsReport));
      if (cb) yield cb({ success: true, data: response.getBroadsoftZeroDurationCdrsReport });
    } else {
      yield put(actions.getBroadsoftZeroDurationCdrsReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getBroadsoftZeroDurationCdrsReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllBroadsoftZeroDurationCdrsReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBroadsoftZeroDurationCdrsReport(payload));
    if (response.getBroadsoftZeroDurationCdrsReport && response.getBroadsoftZeroDurationCdrsReport) {
      yield put(actions.getAllBroadsoftZeroDurationCdrsReportSuccess(response.getBroadsoftZeroDurationCdrsReport));
      if (cb) yield cb({ success: true, data: response.getBroadsoftZeroDurationCdrsReport });
    } else {
      yield put(actions.getAllBroadsoftZeroDurationCdrsReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllBroadsoftZeroDurationCdrsReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getRawCdrDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRawCdrData(payload));
    if (response.getBroadSoftRawCdrData && response.getBroadSoftRawCdrData) {
      yield put(actions.getRawCdrDataSuccess(response.getBroadSoftRawCdrData));
      if (cb) yield cb({ success: true, data: response.getBroadSoftRawCdrData });
    } else {
      yield put(actions.getRawCdrDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRawCdrDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllRawCdrDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRawCdrData(payload));
    if (response.getBroadSoftRawCdrData && response.getBroadSoftRawCdrData) {
      yield put(actions.getAllRawCdrDataSuccess(response.getBroadSoftRawCdrData));
      if (cb) yield cb({ success: true, data: response.getBroadSoftRawCdrData });
    } else {
      yield put(actions.getAllRawCdrDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllRawCdrDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getConfigFailureCodesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigFailureCodes(payload));
    yield put(isEndConnected());
    if (response.getConfigFailureCodes) {
      if (cb) yield cb({ success: true, data: response.getConfigFailureCodes });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getConfigIddSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigIdd(payload));
    yield put(isEndConnected());
    if (response.getConfigIdd) {
      if (cb) yield cb({ success: true, data: response.getConfigIdd });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getConfigIdoSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigIdo(payload));
    yield put(isEndConnected());
    if (response.getConfigIdo) {
      if (cb) yield cb({ success: true, data: response.getConfigIdo });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getConfigIncomingOutgoingRouteSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigIncomingOutgoingRoute(payload));
    yield put(isEndConnected());
    if (response.getConfigIncomingOutgoingRoute) {
      if (cb) yield cb({ success: true, data: response.getConfigIncomingOutgoingRoute });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getSplitTaxPerLineConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    // const { accountId, filter } = payload;
    const response = yield call(queryRequest, getSplitTaxPerLine(payload));
    if (response.getSplitTaxPerLine) {
      if (cb) cb({ success: true, data: response.getSplitTaxPerLine });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* getConfigFailureCodesListSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    // const { accountId, filter } = payload;
    const response = yield call(queryRequest, getConfigFailureCodesList(payload));
    if (response.getConfigFailureCodesList) {
      if (cb) cb({ success: true, data: response.getConfigFailureCodesList });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* getConfigSreservedSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    // const { accountId, filter } = payload;
    const response = yield call(queryRequest, getConfigSreserved(payload));
    if (response.getConfigSreserved) {
      if (cb) cb({ success: true, data: response.getConfigSreserved });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* getConfigOrganizationSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    // const { accountId, filter } = payload;
    const response = yield call(queryRequest, getConfigOrganization(payload));
    if (response.getConfigOrganization) {
      if (cb) cb({ success: true, data: response.getConfigOrganization });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* getNonBroadSoftRawCdrDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getNonBroadSoftRawCdrData(payload));
    if (response.getNonBroadSoftRawCdrData && response.getNonBroadSoftRawCdrData) {
      yield put(actions.getNonBroadSoftRawCdrDataSuccess(response.getNonBroadSoftRawCdrData));
      if (cb) yield cb({ success: true, data: response.getNonBroadSoftRawCdrData });
    } else {
      yield put(actions.getNonBroadSoftRawCdrDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getNonBroadSoftRawCdrDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllNonBroadSoftRawCdrDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getNonBroadSoftRawCdrData(payload));
    if (response.getNonBroadSoftRawCdrData && response.getNonBroadSoftRawCdrData) {
      yield put(actions.getAllNonBroadSoftRawCdrDataSuccess(response.getNonBroadSoftRawCdrData));
      if (cb) yield cb({ success: true, data: response.getNonBroadSoftRawCdrData });
    } else {
      yield put(actions.getAllNonBroadSoftRawCdrDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllNonBroadSoftRawCdrDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getOutputTemplateByTypeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getOutputTemplateByType(payload));
    yield put(isEndConnected());
    if (response.getOutputTemplateByType) {
      if (cb) yield cb({ success: true, data: response.getOutputTemplateByType });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getNonBroadsoftZeroDurationCdrsReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getNonBroadsoftZeroDurationCdrsReport(payload));
    if (response.getNonBroadsoftZeroDurationCdrsReport && response.getNonBroadsoftZeroDurationCdrsReport) {
      yield put(actions.getNonBroadsoftZeroDurationCdrsReportSuccess(response.getNonBroadsoftZeroDurationCdrsReport));
      if (cb) yield cb({ success: true, data: response.getNonBroadsoftZeroDurationCdrsReport });
    } else {
      yield put(actions.getNonBroadsoftZeroDurationCdrsReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getNonBroadsoftZeroDurationCdrsReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllNonBroadsoftZeroDurationCdrsReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getNonBroadsoftZeroDurationCdrsReport(payload));
    if (response.getNonBroadsoftZeroDurationCdrsReport && response.getNonBroadsoftZeroDurationCdrsReport) {
      yield put(
        actions.getAllNonBroadsoftZeroDurationCdrsReportSuccess(response.getNonBroadsoftZeroDurationCdrsReport)
      );
      if (cb) yield cb({ success: true, data: response.getNonBroadsoftZeroDurationCdrsReport });
    } else {
      yield put(actions.getAllNonBroadsoftZeroDurationCdrsReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllNonBroadsoftZeroDurationCdrsReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getRawSmsDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRawSmsData(payload));
    if (response.getRawSmsData && response.getRawSmsData) {
      yield put(actions.getRawSmsDataSuccess(response.getRawSmsData));
      if (cb) yield cb({ success: true, data: response.getRawSmsData });
    } else {
      yield put(actions.getRawSmsDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRawSmsDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllRawSmsDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRawSmsData(payload));
    if (response.getRawSmsData && response.getRawSmsData) {
      yield put(actions.getAllRawSmsDataSuccess(response.getRawSmsData));
      if (cb) yield cb({ success: true, data: response.getRawSmsData });
    } else {
      yield put(actions.getAllRawSmsDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllRawSmsDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getDataServiceReportDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDataServiceReportData(payload));
    if (response.getDataServiceReportData && response.getDataServiceReportData) {
      yield put(actions.getDataServiceReportDataSuccess(response.getDataServiceReportData));
      if (cb) yield cb({ success: true, data: response.getDataServiceReportData });
    } else {
      yield put(actions.getDataServiceReportDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getDataServiceReportDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllDataServiceReportDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDataServiceReportData(payload));
    if (response.getDataServiceReportData && response.getDataServiceReportData) {
      yield put(actions.getListAllDataServiceReportDataSuccess(response.getDataServiceReportData));
      if (cb) yield cb({ success: true, data: response.getDataServiceReportData });
    } else {
      yield put(actions.getListAllDataServiceReportDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllDataServiceReportDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getDataServiceReportDetailsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDataServiceReportDetails(payload));
    if (response.getDataServiceReportDetails && response.getDataServiceReportDetails) {
      if (cb) yield cb({ success: true, data: response.getDataServiceReportDetails });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());

    if (cb) yield cb({ success: false });
  }
}

export function* getAllDataServiceReportDetailsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDataServiceReportDetails(payload));
    if (response.getDataServiceReportDetails) {
      if (cb) yield cb({ success: true, data: response.getDataServiceReportDetails });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* applyPaymentSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const response = yield call(mutationRequest, applyPayment(payload));
    if (response && response.applyPayment) {
      yield put(isEndConnected());
      if (response.applyPayment) {
        if (cb) cb({ success: true, data: response.applyPayment });
        if (response.applyPayment.paymentSuspenseId) {
          toast.success(i18n.t('common:apiMessage.paymentHasGoneToSuspense'));
        } else {
          toast.success(i18n.t('common:apiMessage.paymentIsSuccessful'));
        }
      } else if (cb) cb({ success: false, data: null });
    }
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* searchAccountsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchAccounts({ page, size, filter, sort }));
    if (response.searchAccounts) {
      // toast.success(i18n.t('common:label.mandatory'));
      yield put(actions.searchAccountsSuccess(response.searchAccounts));
    } else {
      yield put(actions.searchAccountsSuccess([]));
    }
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.searchAccounts });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* getParentAccountSaga({ payload, cb }) {
  // yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAccountDetail(payload));
    if (response.getAccountById) {
      yield put({
        type: types.GET_PARENT_ACCOUNT_DETAIL_SUCCESS,
        payload: response.getAccountById,
      });
      if (cb) cb();
    }
    // yield put(isEndConnected());
  } catch (err) {
    // yield put(isEndConnected());
  }
}

export default function* appSaga() {
  yield takeEvery(types.GET_SELF_CARE_OFFERS, getSelfCareOffersSaga);
  yield takeLatest(types.GET_BUNDLE_ID_BY_PACKAGE_ID, getBundleByPackageIdSaga);
  yield takeEvery(types.GET_ACCOUNT_BY_ID, getAccountByIdSaga);
  yield takeLatest(types.MANAGE_ACCOUNT, manageAccountSaga);
  yield takeLatest(types.SEARCH_PACKAGE, searchPackageSaga);
  yield takeLatest(types.GET_ROLE_AND_ROLE_GROUP_BY_ID, getRolesAndRoleGroupByUserIdSaga);
  yield takeEvery(types.GET_ROLE_BY_ID, getRoleByIdSaga);
  yield takeEvery(types.GET_ROLE_ID_BY_ROLEGROUP, getRoleIdByRoleGroupSaga);
  yield takeLatest(types.SELECT_INVOICE_UNITS_ID, selectInvoiceUnitsId);
  yield takeLatest(types.MODIFY_ACCOUNT_BY_ID, modifyAccountByIdSaga);
  yield takeLatest(types.SEARCH_ORDERS, searchOrdersSaga);
  yield takeLatest(types.GET_CUSTOMER_ACTIVITY, getCustomerActivitySaga);
  yield takeLatest(types.GET_ACTIVITY_BY_ID, getActivityByIdSaga);
  yield takeLatest(types.SEARCH_NOTE_UNITS, searchNotesUnitsSaga);
  yield takeLatest(types.GET_CUSTOM_ATTRIBUTES, getCustomAttributesByObjectTypeSaga);
  yield takeLatest(types.GET_S3_BUCKET, getS3BuctketSaga);
  yield takeEvery(types.READ_FILE_FROM_S3, readFileFromS3Saga);
  yield takeEvery(types.GET_TENANT_SELF_CARE_CONFIG, getTenantSelfCareSaga);
  yield takeEvery(types.GET_TENANT_DEFAULT_CONFIG, getTenantDefaultConfigSaga);
  yield takeEvery(types.GET_AWS_S3_CREDENTIAL, getAwsS3CredentialSaga);
  yield takeEvery(types.GET_OBJECT_FILE_BY_ID, getObjectFileByIdSaga);
  yield takeEvery(types.SEARCH_PAYMENT_BY_ACCOUNT, searchPaymentByAccountSaga);
  yield takeEvery(types.REDIRECT_MCM_SELF_CARE, redirectMCMSelfCareSaga);
  yield takeLatest(types.UPLOAD_MULTI_PART_FILE, uploadFileSaga);
  yield takeLatest(types.GET_CURRENCY_CONFIG, getCurrencyConfigAppSaga);
  yield takeEvery(types.GET_CCP_PROPERTIES_CONFIG, getCcpPropertiesConfigSaga);
  yield takeLatest(types.GET_BROADSOFT_ZERO_DURATION_CDRS_REPORT, getBroadsoftZeroDurationCdrsReportSaga);
  yield takeLatest(types.GET_ALL_BROADSOFT_ZERO_DURATION_CDRS_REPORT, getAllBroadsoftZeroDurationCdrsReportSaga);
  yield takeLatest(types.GET_RAW_CDR_DATA_REPORT, getRawCdrDataSaga);
  yield takeLatest(types.GET_ALL_RAW_CDR_DATA_REPORT_DETAILS, getAllRawCdrDataSaga);
  yield takeLatest(types.GET_CONFIG_FAILURE_CODES, getConfigFailureCodesSaga);
  yield takeLatest(types.GET_CONFIG_IDD, getConfigIddSaga);
  yield takeLatest(types.GET_CONFIG_IDO, getConfigIdoSaga);
  yield takeLatest(types.GET_CONFIG_INCOMING_OUTGOING_ROUTE, getConfigIncomingOutgoingRouteSaga);
  yield takeLatest(types.GET_SPLIT_TAX_PER_LINE_CONFIG, getSplitTaxPerLineConfigSaga);
  yield takeLatest(types.GET_CONFIG_FAILURE_CODES_LIST, getConfigFailureCodesListSaga);
  yield takeLatest(types.GET_CONFIG_SRESERVED, getConfigSreservedSaga);
  yield takeLatest(types.GET_CONFIG_ORGANIZATION, getConfigOrganizationSaga);
  yield takeLatest(types.GET_NON_BROAD_SOFT_RAW_CDR_DATA, getNonBroadSoftRawCdrDataSaga);
  yield takeLatest(types.GET_ALL_NON_BROAD_SOFT_RAW_CDR_DATA, getAllNonBroadSoftRawCdrDataSaga);
  yield takeLatest(types.GET_OUTPUT_TEMPLATE_BY_TYPE, getOutputTemplateByTypeSaga);
  yield takeLatest(types.GET_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT, getNonBroadsoftZeroDurationCdrsReportSaga);
  yield takeLatest(
    types.GET_ALL_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT,
    getAllNonBroadsoftZeroDurationCdrsReportSaga
  );
  yield takeLatest(types.GET_RAW_SMS_DATA_REPORT, getRawSmsDataSaga);
  yield takeLatest(types.GET_ALL_RAW_SMS_DATA_REPORT_DETAILS, getAllRawSmsDataSaga);
  yield takeLatest(types.GET_LIST_ON_DEMAND, getDataServiceReportDataSaga);
  yield takeLatest(types.GET_ALL_LIST_ON_DEMAND, getAllDataServiceReportDataSaga);
  yield takeLatest(types.GET_DATA_SERVICE_REPORT_DETAILS, getDataServiceReportDetailsSaga);
  yield takeLatest(types.GET_ALL_DATA_SERVICE_REPORT_DETAILS, getAllDataServiceReportDetailsSaga);
  yield takeLatest(types.APPLY_PAYMENT, applyPaymentSaga);
  yield takeLatest(types.SEARCH_ACCOUNTS, searchAccountsSaga);
  yield takeLatest(types.GET_PARENT_ACCOUNT_DETAIL, getParentAccountSaga);
}
