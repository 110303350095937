import { createSelector } from 'reselect';

const selectGlobal = state => {
  return state.get('auth');
};

const makeGetPermissionSelfCare = () =>
  createSelector(selectGlobal, globalState => {
    const { selfCareHubModules } = globalState.get('permissionsApp');
    if (selfCareHubModules) {
      return selfCareHubModules.filter(a => a.moduleName === 'SELF_CARE')[0];
    }
    return {};
  });

const makeGetS3Config = () => createSelector(selectGlobal, items => items.get('s3Config'));

const makeGetLogoConfig = () => createSelector(selectGlobal, items => items.get('logo'));

const makeGetCurrencyConfig = () =>
  createSelector(selectGlobal, items => (items.get('currencyConfig') ? items.get('currencyConfig') : null));

const makeGetAccountInfo = () =>
  createSelector(selectGlobal, items => (items.get('accountInfo') ? items.get('accountInfo') : null));

const makeGetCcpProperty = () =>
  createSelector(selectGlobal, items => (items.get('ccpProperty') ? items.get('ccpProperty') : null));

// getBroadsoftZeroDurationCdrsReport
const makeGetListBroadsoftZeroDurationCdrst = () =>
  createSelector(selectGlobal, items => items.get('listBroadsoftZeroDurationCdrst'));
const makeGetListAllBroadsoftZeroDurationCdrst = () =>
  createSelector(selectGlobal, items => items.get('listAllBroadsoftZeroDurationCdrst'));

const makeGetPermissionsReports = () =>
  createSelector(selectGlobal, item => {
    const opsHubModules = item.get('permissionsApp').opsHubModules;
    if (opsHubModules) {
      return opsHubModules.filter(a => a.moduleName === 'REPORTS')[0];
    }
    return {};
  });

// Raw Cdr Data
const makeGetListRawCdrData = () => createSelector(selectGlobal, items => items.get('listRawCdrData'));
const makeGetListAllRawCdrData = () => createSelector(selectGlobal, items => items.get('listAllRawCdrData'));

// NonBroadSoftRawCdrData
const makeGetListRawNonBroadsoft = () => createSelector(selectGlobal, items => items.get('listNonBroadsoft'));
const makeGetListAllRawNonBroadsoft = () => createSelector(selectGlobal, items => items.get('listAllNonBroadsoft'));

// getNonBroadsoftZeroDurationCdrsReport
const makeGetListNonBroadsoftZeroDurationCdrs = () =>
  createSelector(selectGlobal, items => items.get('listNonBroadsoftZeroDurationCdrs'));
const makeGetListAllNonBroadsoftZeroDurationCdrs = () =>
  createSelector(selectGlobal, items => items.get('listAllNonBroadsoftZeroDurationCdrs'));

// Raw Sms Data
const makeGetListRawSmsData = () => createSelector(selectGlobal, items => items.get('listRawSmsData'));
const makeGetListAllRawSmsData = () => createSelector(selectGlobal, items => items.get('listAllRawSmsData'));

// Ld Service Report Data
const makeGetListServiceReportData = () => createSelector(selectGlobal, items => items.get('listServiceReportData'));
const makeGetListAllServiceReportData = () =>
  createSelector(selectGlobal, items => items.get('listAllServiceReportData'));

const makeGetListCustomers = () => createSelector(selectGlobal, customers => [...customers.get('listCustomers')]);

const makeGetUserDetails = () => createSelector(selectGlobal, items => items.get('userDetails'));

const makeGetSelfCareConfig = () => createSelector(selectGlobal, items => items.get('selfCareConfig'));

export {
  makeGetPermissionSelfCare,
  makeGetS3Config,
  makeGetLogoConfig,
  makeGetCurrencyConfig,
  makeGetAccountInfo,
  makeGetCcpProperty,
  makeGetListBroadsoftZeroDurationCdrst,
  makeGetListAllBroadsoftZeroDurationCdrst,
  makeGetPermissionsReports,
  makeGetListRawCdrData,
  makeGetListAllRawCdrData,
  makeGetListRawNonBroadsoft,
  makeGetListAllRawNonBroadsoft,
  makeGetListNonBroadsoftZeroDurationCdrs,
  makeGetListAllNonBroadsoftZeroDurationCdrs,
  makeGetListRawSmsData,
  makeGetListAllRawSmsData,
  makeGetListServiceReportData,
  makeGetListAllServiceReportData,
  makeGetListCustomers,
  makeGetUserDetails,
  makeGetSelfCareConfig,
};
